import React from 'react'
import {
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Tooltip,
    Typography
} from "@mui/material"
import useStyles from "./styles"
// import copy from 'copy-to-clipboard'

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import BoltIcon from '@mui/icons-material/Bolt'

import ConnectorType_CCS from '../../../../assets/connectors/gw-plug-CCS.svg'
import ConnectorType_CHAdeMO from '../../../../assets/connectors/gw-plug-CHAdeMO.svg'
import ConnectorType_Type2Socket from '../../../../assets/connectors/gw-plug-type2.svg'
import ConnectorType_Type2Cable from '../../../../assets/connectors/gw-plug-type2+plug.svg'
import ConnectorType_PlugUnknown from '../../../../assets/connectors/gw-plug-unknown.svg'
import {getColor, getClientZoneUrl, getConnectorName, getParamValue, getBearerToken} from '../../../../utils'
import {useTranslation} from "react-i18next"
import useCurrentStates from "../../../../hooks/useCurrentStates"
import useToggleCurrentStates from "../../../../hooks/useToggleCurrentState"
import useLastUsedConnector from "../../../../hooks/useLastUsedConnector";

const ListOfConnectors = ({isCharging, place}) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {currentStates} = useCurrentStates()
    const {setToggleCurrentStates} = useToggleCurrentStates()
    const {setLastUsedConnector} = useLastUsedConnector()

    const getPrice = (connector) => {
        if(connector.is_free) {
            return t('locationMap.freeOfChargeConnector')
        }

        // IF not logged in -> show best price if available
        if(getBearerToken() === null && typeof connector.best_price !== 'undefined') {
            if(connector.best_price.priceKwh && connector.best_price.currency && connector.best_price.priceMinute && connector.best_price.currency) {
                return `${t('locationMap.from')} ${Number(connector.best_price.priceKwh).toFixed(2)} ${connector.best_price.currency}/kWh`
            }
        }

        // otherwise send classic price
        if(typeof connector.price !== 'undefined') {
            return `${Number(connector.price.priceKwh).toFixed(2)} ${connector.price.currency}/kWh, ${Number(connector.price.priceMinute).toFixed(2)} ${connector.price.currency}/min`
        }

        return ''
    }

    const getStatus = (connector) => {
        if(isCharging && connector?.connector_id === isCharging.connector['connector_id']) {
            return t('locationMap.charging')
        }

        if(!connector.availability) {
            return t('locationMap.unknown')
        }

        switch (connector.availability.toLowerCase()) {
            case 'charging':
                return t('locationMap.charging')
            case 'available':
            case 'preparing':
            case 'finishing':
                return t('locationMap.available')

            case 'out_of_working_time':
                return t('locationMap.closed')

            case 'closed':
            case 'maintenance':
                return t('locationMap.outOfOrder')

            case 'occupied':
            case 'suspended_ev':
            case 'suspended_evse':
                return t('locationMap.occupied')

            case 'offline':
                return t('locationMap.offline')
            case 'reserved':
            case 'newly_inserted':
            case 'out_of_communication':
            default:
                return t('locationMap.unknown')
        }
    }

    const getConnectorIcon = (type) => {
        switch (type) {
            case 'ConnectorType_CHAdeMO':
                return <img src={ConnectorType_CHAdeMO} alt={type}/>
            case 'ConnectorType_CCS':
                return <img src={ConnectorType_CCS} alt={type}/>
            case 'ConnectorType_Type2Cable':
                return <img src={ConnectorType_Type2Cable} alt={type}/>
            case 'ConnectorType_Type2Socket':
                return <img src={ConnectorType_Type2Socket} alt={type}/>
            case 'ConnectorType_HomeDe':
            default:
                return <img src={ConnectorType_PlugUnknown} alt={type}/>
        }
    }

    const isConnectorCurrentState = (connector, currentStates) => {
        return Boolean(currentStates.map(el => el.connector['connector_id']).includes(connector['connector_id']))
    }

    const getConnectorLabel = (owner, connector) => {
        return owner.toLowerCase() === 'roaming' ? connector.printed_label : `${connector.printed_label}`
    }

    return (
        place && place.devices?.length && (
            <div className={classes.container}>
                <Grid container>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant={"subtitle2"}>
                            {t('locationMap.listOfConnectors')}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container>
                    {
                        place?.devices?.map((device, index) => (
                            <Grid item xs={12} key={index}>
                                <Grid item xs={12} className={classes.deviceWrapper}>
                                    <span className={classes.chargerTitle}>
                                        <BoltIcon/>
                                        <Typography variant={"subtitle2"}>
                                            {t('locationMap.charger')} {device['printed_label']}
                                        </Typography>
                                    </span>
                                </Grid>
                                <List component="nav" className={classes.connectorsWrapper}>
                                    {
                                        device.connectors.map((connector, index) => (
                                            <Tooltip title={connector.code}
                                                     placement="top"
                                                     key={index}
                                            >
                                                <ListItem key={index}>
                                                <ListItemButton className={classes.connectorListItem}
                                                                onClick={() => {
                                                                    if(currentStates?.length && isConnectorCurrentState(connector, currentStates)) {
                                                                        setToggleCurrentStates(true)
                                                                    } else {
                                                                        setLastUsedConnector(connector.connector_id)
                                                                        const redirectLink = getParamValue('startChargingRedirectLink') || `${getClientZoneUrl(window.location.origin)}/charging?connectorId=${connector.connector_id}`
                                                                        // copy(redirectLink)
                                                                        window.open(redirectLink, '_top')
                                                                    }
                                                                }}
                                                >
                                                    <ListItem className={classes.connectorLeftSideWrapper}>
                                                        <ListItemText primary={getConnectorIcon(connector.type)}
                                                                      className={classes.connectorIcon}
                                                                      secondary={getConnectorName(connector.type, t)}
                                                        />
                                                            <ListItemText primary={getConnectorLabel(place.owner_type, connector)}
                                                                          className={classes.connectorPrice}
                                                                          secondaryTypographyProps={{ style: { whiteSpace: "pre-line" } }}
                                                                          secondary={
                                                                                getPrice(connector)
                                                                          }
                                                            />
                                                    </ListItem>
                                                    <ListItem className={classes.iconForward}
                                                              secondaryAction={
                                                                  <IconButton edge="end">
                                                                      <ArrowForwardIosIcon/>
                                                                  </IconButton>
                                                              }
                                                    >
                                                        <ListItemText primary={getStatus(connector)}
                                                                      secondary={`${connector['max_power'] ? connector['max_power'] : '?'} kW`}
                                                                      className={getColor(connector?.availability, classes)}
                                                        />
                                                    </ListItem>
                                                </ListItemButton>
                                            </ListItem>
                                            </Tooltip>
                                        ))
                                    }
                                </List>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        )
    )
}

export default ListOfConnectors
